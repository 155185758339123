import React from "react";
import { PageProps } from "gatsby";

import DocsHelmet from "components/docs/shared-components/DocsHelmet";
import HeaderBar from "components/docs/layout/HeaderBar";
import CrossGuideLinks from "components/docs/navigation/CrossGuideLinks";
import styled from "styled-components";
import { StaticPageContainer } from "components/docs/shared-components/PageContainers";

export const CenteredDoc = styled.div`
  width: 100%;
  text-align: center;
  align-items: center;
`;

const YoutubeIframe = styled.iframe`
  height: 623px;
  width: 100%;
`;

/**
 * Creates a Overview Video page for Field Mapping guide
 */
const OverviewVideo = ({
  location,
}: PageProps<unknown, unknown, Record<string, unknown> | null>) => {
  const title = "Overview Video";
  const description =
    "Watch this quick video guide to learn the basics of how Merge’s Field Mapping works.";

  return (
    <StaticPageContainer style={{ paddingBottom: "0" }}>
      <DocsHelmet title={title} description={description} />
      <HeaderBar title={title} subtitle={description} />
      <YoutubeIframe
        src="https://www.youtube.com/embed/VX8LTX78oJg"
        title="YouTube video player"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
        className="mb-9"
      />
      <CrossGuideLinks location={location} usesQuaternaryLinks />
    </StaticPageContainer>
  );
};

export default OverviewVideo;
